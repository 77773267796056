import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_share_ships',
  attributes: [
    'id',
    'campaign_id',
    'share_id',
    'state',
    'campaign_periods_count',
    'campaign_period_share_ids',
    'has_sample',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'campaign_period_ids',
    'contact_phone',
    'sample_address',
    'remember_sample_address'
  ]
}

export default class PromoterCampaignShareShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    if (!this.id && !this.state) this.state = 'waited'
    if (!this.sample_address || !this.sample_address.city)
      this.sample_address = { city: null, area: null, street_address: null }
  }

  static register({ share_id, campaign_id, model }) {
    return axios.put(
      `${new this().apiBasePath()}/register?promoter_share_id=${share_id}&promoter_campaign_id=${campaign_id}`,
      {
        data: {
          type: 'campaign-share-ship-register',
          attributes: model
        }
      }
    )
  }

  acceptRegistration(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/accept_registration`, {
      data: {
        type: 'accept-registration',
        attributes: form
      }
    })
  }

  static acceptInvitation({ share_id, campaign_id }) {
    return axios.put(
      `${new this().apiBasePath()}/accept_invitation?promoter_share_id=${share_id}&promoter_campaign_id=${campaign_id}`
    )
  }

  // for stats
  static fetchModelStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/model_stats?${queryString}`)
  }

  updatePeriods(periodIds) {
    return axios.post(`${this.apiBasePath()}/${this.id}/periods`, {
      data: {
        type: 'update-campaign-period-shares',
        attributes: {
          period_ids: periodIds
        }
      }
    })
  }
}
