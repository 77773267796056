import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionService from '@services/fetch_stats_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'partners',
  attributes: [
    'id',
    'email',
    'name',
    'is_suspended',
    'products_count',
    'product_sku_prefix',
    'company_info',
    'created_at',
    'updated_at',
    'tag_list',
    'setting_fee_paid_at',
    'subscription_started_at',
    'subscription_ended_at',
    'current_stored_values',
    'admin_note'
  ],
  editableAttributes: [
    'email',
    'name',
    'ori_password',
    'password',
    'new_password',
    'password_confirmation',
    'product_sku_prefix',
    'company_info',
    'tag_list'
  ]
}

import PartnerCompanyInfo from './partner_company_info.js'

export default class Partner extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    this.company_info = new PartnerCompanyInfo(
      this.company_info || {}
    ).attributes({ all: true })
  }

  static all(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  static getCurrentPartner() {
    return axios.get(`${new this().apiBasePath()}/current_partner`)
  }

  /**
   * 將此 partner 停權
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  suspend() {
    return axios.put(`${this.apiBasePath()}/${this.id}/suspend`)
  }

  updateSubscriptionStartedAt(subscriptionStartedAt) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/update_subscription_started_at`,
      {
        data: {
          type: 'update-subscription-started-at',
          attributes: {
            subscription_started_at: subscriptionStartedAt
          }
        }
      }
    )
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(options)}`
    )
  }

  getProduct(productId) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products/${productId}`)
  }

  createProduct(productForm) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: productForm
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/products`, requestBody)
  }

  updateProduct(productId, productForm) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: productForm
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/products/${productId}`,
      requestBody
    )
  }

  fetchProductPreferences(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/product_preferences?${FetchingDataOptionsService.call(options)}`
    )
  }

  // orders
  static fetchOrderStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(`${new this().apiBasePath()}/orders/stats?${queryString}`)
  }

  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  getOrder(token) {
    return axios.get(`${this.apiBasePath()}/${this.id}/orders/${token}`)
  }

  fetchPromoterMatchAnswerShares(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answered_shares?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  fetchPromoterMatchAnsweredShareStats(options) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answered_shares/stats?${queryString}`
    )
  }

  fetchPromoterMatchAnswers(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answers?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchPromoterMatchProducts(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_products?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchPromoterMatchProductStats(options) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_products/stats?${queryString}`
    )
  }

  createPromoterCampaign(form) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: form
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/promoter_campaigns`,
      requestBody
    )
  }

  fetchPromoterCampaigns(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_campaigns?${FetchingDataOptionsService.call(options)}`
    )
  }

  batchSaveCampaignShareShips({ promoterShareIds }) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-campaign-share-ships',
        attributes: {
          share_ids: promoterShareIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_campaign_share_ships/batch_save`,
      requestBody
    )
  }

  updateTagList() {
    const requestBody = {
      data: {
        type: 'partners',
        attributes: {
          tag_list: this.tag_list
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/tag_list`, requestBody)
  }

  get oddPayApiBasePath() {
    return `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}/odd_pay/partners`
  }

  static choosePlan(oddPayInvoice) {
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/choose_plan`,
      oddPayInvoice.requestBody()
    )
  }

  static currentNormalInvoice() {
    return axios.get(`${new this().oddPayApiBasePath}/invoices/current_normal`)
  }

  static currentSubscriptionInvoice() {
    return axios.get(
      `${new this().oddPayApiBasePath}/invoices/current_subscription`
    )
  }

  static confirmOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          email: oddPayInvoice.email,
          name: oddPayInvoice.name,
          contact_phone: oddPayInvoice.contact_phone,
          company_name: oddPayInvoice.company_name,
          company_ein: oddPayInvoice.company_ein,
          address: oddPayInvoice.address,
          note: oddPayInvoice.note,
          payment_method_id: oddPayInvoice.payment_method_id
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${oddPayInvoice.id}/confirm`,
      requestBody
    )
  }

  static makePaymentForOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          payment_method_id: oddPayInvoice.payment_method_id
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/make_payment`,
      requestBody
    )
  }

  static fetchInvoices(options = {}) {
    return axios.get(
      `${
        new this().oddPayApiBasePath
      }/invoices?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchOddPayInvoices(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/odd_pay_invoices?${FetchingDataOptionsService.call(options)}`
    )
  }

  payOddPayInvoiceByCash(form) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/odd_pay_invoices/pay_by_cash`,
      {
        data: {
          type: 'odd_pay_invoice_pay_by_cash',
          attributes: form
        }
      }
    )
  }

  // reward_account
  fetchRewardAccounts() {
    return axios.get(`${this.apiBasePath()}/${this.id}/reward_accounts`)
  }

  fetchSubscriptionInfos(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/subscription_infos?${FetchingDataOptionsService.call(options)}`
    )
  }

  signUp() {
    return axios.post(`${this.apiBasePath()}/sign_up`, this.requestBody())
  }

  updateProfileAccount() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/account`,
      this.requestBody()
    )
  }

  updateProfilePassword() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/password`,
      this.requestBody()
    )
  }

  updateProfileBasicInfo() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/basic_info`,
      this.requestBody()
    )
  }

  updateProfileBankAccount() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/bank_account`,
      this.requestBody()
    )
  }

  updateProfileContact() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/contact`,
      this.requestBody()
    )
  }

  updateProfileAdvancedInfo() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/advanced_info`,
      this.requestBody()
    )
  }

  updateAdminNote(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/profile/admin_note`, {
      data: {
        type: 'update-admin-note',
        attributes: form
      }
    })
  }
}
