import * as types from '../mutation-types'

export const fetchRewardAccounts = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'fetchRewardAccounts')

  return new Promise((resolve, reject) => {
    model
      .fetchRewardAccounts()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchRewardAccounts')

        dispatch('rewardAccounts/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRewardAccounts,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
