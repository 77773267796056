import PromoterCampaign from '../../resource_models/promoter_campaign'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterCampaign(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterCampaign(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const statsData =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].data : []
  }

export const statsMeta =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].meta : null
  }
